import * as React from 'react';
import { FC, useRef, useCallback, useEffect, useState, memo } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { getUserCountryCode } from '../api';

interface PhoneInputField {
  value: string;
  handleBlur?: (e: any) => void;
  touched?: boolean;
  error?: string;
  handleChange: (e: React.ChangeEvent<HTMLInputElement> | string) => void;
  disabled?: boolean;
  userCountry?: string;
  ariaLabel?: string;
  ariaDescribedby?: string;
  setCountryData?: (value: { countryCode: string; dialCode: string }) => void;
}

const PhoneInputFieldComponent: FC<PhoneInputField> = ({
  value,
  handleBlur,
  touched,
  error,
  handleChange,
  disabled,
  setCountryData,
  ariaLabel,
  ariaDescribedby,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [userCountry, setUserCountry] = useState(null);

  const onValueChange = (phoneNumber, numberData) => {
    setCountryData({
      countryCode: numberData.countryCode,
      dialCode: numberData.dialCode,
    });

    if (handleChange !== null) {
      handleChange(phoneNumber);
    }
  };

  const onBlurHandler = (e) => {
    handleBlur && handleBlur(e);
  };

  const getCountryCode = useCallback(async () => {
    try {
      const country = await getUserCountryCode();
      setUserCountry(country);
    } catch {
      setUserCountry('us')
    }
  }, []);

  useEffect(() => {
    getCountryCode();
  }, []);

  return (
    <div className="amplify-flex amplify-field amplify-textfield">
      {!!userCountry && (
        <PhoneInput
          countryCodeEditable={false}
          ref={inputRef}
          placeholder="Enter phone number"
          inputProps={{
            name: 'phone',
            'aria-label': ariaLabel,
            'aria-describedby': ariaDescribedby,
          }}
          value={value}
          onChange={onValueChange}
          country={userCountry || 'us'} // if not defined, fallback to us
          onBlur={onBlurHandler}
          isInvalid={touched && error}
          disabled={disabled}
          error={error}
          className={touched && !!error ? 'phone-with-error' : ''}
        />
      )}
      {touched && !!error && (
        <p className="amplify-text amplify-field__error-message">{error}</p>
      )}
    </div>
  );
};

export const PhoneInputField = memo(PhoneInputFieldComponent);
